<template>
  <div class="vg_wrapper">
    <el-card>
      <div class="vd_button_group vg_mtb_16 flexHV">
        <el-button type="danger" size="small" @click="doDelete()" :disabled="!btn.delete"><i class="el-icon-delete"></i> 删除</el-button>
        <el-button size="small" type="primary" icon="el-icon-search" @click="getTrans()" class="vg_ml_16">查询 </el-button>
        <el-button type="info" size="small" class="vd_export" icon="el-icon-refresh-right" @click="buttonRefresh()">刷新</el-button>
        <el-button :disabled="!btn.delete" type="success" size="small" class="vd_export" @click="directlyEffective()">直接生效</el-button>
        <el-button class="vd_export" size="small" type="primary" @click="syncDialogVisible = true">同步老ERP</el-button>
        <el-date-picker
          class="vg_ml_8 vg_mr_8 topDateRangeSearch"
          size="small"
          v-model="dateRange"
          type="daterange"
          value-format="timestamp"
          range-separator="至"
          start-placeholder="发票开始日期"
          end-placeholder="发票结束日期"
        />
        <div style="margin-left: auto">
          <div style="display: flex">
            <div style="width: 250px">出运金额合计:{{ totalForm.tranPrtotalTotal }}</div>
          </div>
          <div style="display: flex">
            <div style="width: 250px">费用金额合计:{{ totalForm.tranFetotalTotal }}</div>
          </div>
        </div>
      </div>
      <el-col :md="24">
        <search-table
          ref="multiTable"
          :tableRowKey="`tran_id`"
          :data="tableData"
          :columns="columns"
          :totalPage="totalPage"
          @getTableData="getTrans"
          :need-summation="needSummation"
          :need-fixed-height="true"
          @selection-change="handleSelectionChange"
          @select="selectRows"
          @select-all="selectRows"
          @row-dblclick="dbClickJp"
        >
          <template v-slot:status="scope">
            <div style="text-align: center">
              <el-tag :type="helper.getStatusName(scope.row.status).type" size="mini">
                {{ helper.getStatusName(scope.row.status).name }}
              </el-tag>
            </div>
          </template>
          <template v-slot:tran_no="scope"> {{ scope.row.tran_no }}<i v-if="scope.row.autoInc" class="el-icon-finished"></i></template>
        </search-table>
      </el-col>
      <el-dialog :visible.sync="syncDialogVisible" title="同步老ERP" top="0" width="70%">
        <div class="flexHV">
          <span style="margin-left: auto">注意:搜索为精确搜索，搜索过后才显示内容 同步数据将自动生效不可更改</span>
        </div>
        <DynamicUTable
          ref="dialogTable"
          :columns="dialogTableProperties"
          :dbClickJp="dialogDbClick"
          :need-check-box="false"
          :need-search="true"
          :needPagination="false"
          :table-data="dialogTableData"
          @getTableData="getSyncTableData"
        ></DynamicUTable>
      </el-dialog>
    </el-card>
  </div>
</template>

<script>
import { getNoCatch, postNoCatch } from '@api/request';
import { tranAPI } from '@api/modules/tran';
import helper from '@assets/js/helper.js';
import SearchTable from '@/components/table/SearchTableV2.vue';
import { cloneDeep } from 'lodash';
import { listTableProperties, syncDialogTableProperties } from '@/views/TransportationManagement/TranManage/tran';
import { apprAPI } from '@api/modules/appr';
import DynamicUTable from '@/components/table/DynamicUTable.vue';
import { setTime } from '@api/public';
import UrlEncode from '@assets/js/UrlEncode';

export default {
  name: 'TranList',
  components: {
    DynamicUTable,
    SearchTable
  },
  data() {
    return {
      dateRange: [],
      columns: listTableProperties,
      totalForm: {},
      currentPage: 1,
      totalPage: 0,
      tableData: [],
      multiSelection: [],
      btn: {},
      needSummation: false,
      syncDialogVisible: false,
      dialogTableData: [],
      dialogTableProperties: syncDialogTableProperties
    };
  },
  created() {},
  mounted() {
    if (this.$route.query.scon_nos) {
      this.$refs.multiTable.searchForm.scon_nos = this.$route.query.scon_nos;
    }
    this.initData();
  },
  // 出运信息编辑
  watch: {
    $route(to, from) {
      if (from.path === '/tran_edit') {
        this.initData();
      }
    }
  },
  filters: {
    // 时间转换
    formatDate(row) {
      return helper.toStringDate(row);
    }
  },
  methods: {
    initData() {
      this.getTrans();
    },
    async getTrans() {
      let searchForm = cloneDeep(this.$refs.multiTable.searchForm);
      setTime(searchForm, 'start_time', 'end_time', this.dateRange);

      try {
        const { data } = await getNoCatch(tranAPI.getTrans, searchForm);
        this.tableData = data.list;
        this.totalPage = data.total;
        this.btn = data.btn;

        const columnMap = this.columns.reduce((map, column) => {
          map[column.prop] = column;
          return map;
        }, {});

        const temp = Object.keys(data).some(key => {
          if (key.includes('sum_')) {
            const columnKey = key.substring(4);
            if (columnMap[columnKey]) {
              columnMap[columnKey][key] = data[key];
              return true;
            }
          }
          return false;
        });

        this.totalForm.tranPrtotalTotal = data.tranPrtotalTotal;
        this.totalForm.tranFetotalTotal = data.tranFetotalTotal;
        this.needSummation = temp;
      } catch (error) {
        console.error(error);
      }
    },
    buttonRefresh() {
      this.dateRange = [];
      this.currentPage = 1;
      this.$refs.multiTable.searchForm = { page_no: 1 };
      this.$refs.multiTable.clearSelection();
      this.$refs.multiTable.$refs.pubPagination.currentPage = 1;
      this.initData();
    },
    handleSelectionChange(val) {
      this.multiSelection = val;
    },
    selectRows(selection) {
      this.btn.delete = this.helper.isDelete(selection);
    },
    doDelete() {
      if (this.multiSelection.length === 0) {
        this.$message({ type: 'warning', message: '请至少选择一条数据！' });
      } else {
        this.mBox();
      }
    },
    //删除提示
    mBox() {
      this.$confirm('确定删除？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.delRow();
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });
          this.$refs.multiTable.clearSelection();
        });
    },
    // 删除
    delRow() {
      const ids = this.multiSelection.map(item => item.tran_id);
      postNoCatch(tranAPI.deleteTranByIds, { tran_id_list: ids })
        .then(() => {
          this.$message.success('删除成功!');
          if (ids.length === this.tableData.length && this.currentPage > 1) {
            this.currentPage--;
            this.$refs.pubPagination.currentPage = this.currentPage;
          }
        })
        .catch(err => {
          this.$message.error(err);
        })
        .finally(() => {
          this.initData();
        });
    },
    dbClickJp(row) {
      this.jump('/tran_edit', {
        key: UrlEncode.encode(
          JSON.stringify({
            perm_id: 130,
            form_id: row.tran_id
          })
        )
      });
    },
    directlyEffective() {
      if (this.multiSelection.length !== 1) return this.$message.warning('请选择一条数据!');
      postNoCatch(apprAPI.makeEffective, { ...this.$route.query, form_id: this.multiSelection[0].tran_id }).then(() => {
        this.$message.success('生效成功!');
        this.initData();
      });
    },
    getSyncTableData() {
      let deep = cloneDeep(this.$refs.dialogTable.searchForm);
      delete deep.page_no;
      if (Object.values(deep).every(value => !value)) return;
      tranAPI.getTranListByRichMax(this.$refs.dialogTable.searchForm).then(({ data }) => {
        this.dialogTableData = data.list;
      });
    },
    dialogDbClick(row) {
      this.$confirm('确定同步？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          tranAPI.addTranByRichMax(row).then(() => {
            this.$message.success('同步成功!');
            this.syncDialogVisible = false;
            this.$refs.multiTable.searchForm.tran_no = row.tran_no;
            this.getTrans();
          });
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消'
          });
        });
    }
  }
};
</script>

<style scoped lang="scss"></style>
